import React, { useState, useRef } from 'react';

const DropdownItem = ({ title, subtitle, imageSrc, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={styles.container}>
      <div style={styles.header} onClick={toggleDropdown}>
        <div style={styles.titleContainer}>
          {imageSrc && <img src={imageSrc} alt={title} style={styles.image} />}
          <div>
            <span style={styles.title}>{title}</span>
            {subtitle && <span style={styles.subtitle}> {subtitle}</span>}
          </div>
        </div>
        <span style={styles.arrow}>{isOpen ? '▲' : '▼'}</span>
      </div>
      <div
        ref={contentRef}
        style={{
          ...styles.content,
          maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : '0px',
          opacity: isOpen ? 1 : 0,
        }}
      >
        {children}
      </div>
    </div>
  );
};

const styles = {
  container: {
    marginBottom: '10px',
    borderBottom: '1px solid #ddd',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '10px 0',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
  },
  title: {
    fontWeight: 'bold',
  },
  subtitle: {
    fontStyle: 'italic',
    color: '#555',
    marginLeft: '5px',
  },
  arrow: {
    marginLeft: '10px',
  },
  content: {
    overflow: 'hidden',
    transition: 'max-height 0.3s ease, opacity 0.3s ease',
    maxHeight: '0px',
    opacity: 0,
    paddingLeft: '34px',
    paddingBottom: '10px',
  },
};

export default DropdownItem;
