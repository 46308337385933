import React from 'react';
import "../App.css";

export default function Project(props) {
    return (
        <div className="project-body">
            <a href={props.gitHubRepo} target="_blank" rel="noopener noreferrer">
                <h4 className="projectTitle">{props.projectTitle}</h4>
            </a>
                <p1>{props.optionalDescription}</p1>
                <p className="techStack"> {props.techStack}</p>
            <a href={props.hitHubRepo} target="_blank" rel="noopener noreferrer">
            {/* <img className="project-img" src={props.picURL} alt={props.altText}/> */}
            </a>
        </div>
    )
}
