import React from 'react';
import { ReactComponent as MySVG } from './images/ZHCLe201.svg'; // Adjust the path as needed

const SvgDrawing = ({ darkMode }) => {
  return (
    <div style={styles.container}>
      <MySVG
        width="500px"
        height="500px"
        style={{
          filter: darkMode ? 'invert(1)' : 'invert(0)', // Invert colors in dark mode
          transition: 'filter 0.3s ease-in-out', // Smooth transition between modes
        }}
      />
    </div>
  );
};

const styles = {
  container: {
    position: 'absolute',
    bottom: '0px',
    right: '0px',
  },
};

export default SvgDrawing;
