import React from 'react';
import "../App.css";
import { AiFillLinkedin, AiFillMail, AiFillGithub } from "react-icons/ai";
import SvgDrawing from '../components/svg';

export default function Home({ darkMode }) {
    return (
        <div className="page-padding">
            <div className="intro">
                <h5>Hi, I'm Arian Gokhale</h5>
                <p>I made this site to practice React and write about myself on the internet.</p>
                <p>Feel free to look around and reach out if anything is of interest :)</p>

                <div className="contact-icons">
                    <a href="mailto:gokhalearian@gmail.com" className="contact-link" aria-label="Email">
                        <AiFillMail className="contact-icon" />
                    </a>
                    <a href="https://www.linkedin.com/in/ariangokhale/" className="contact-link" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                        <AiFillLinkedin className="contact-icon" />
                    </a>
                    <a href="https://github.com/ariangokhale" className="contact-link" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
                        <AiFillGithub className="contact-icon" />
                    </a>
                </div>
            </div>


        <SvgDrawing className="svg-element" darkMode={darkMode}/>

        </div>
    );
}
