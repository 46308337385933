import React, { useMemo, useState } from 'react';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Log from './pages/Log';
import Navbar from './components/navbar';
import ThemeToggle from './components/ThemeToggle';
import './App.css';

export default function App() {
  const [darkMode, setDarkMode] = useState(false);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
        },
      }),
    [darkMode]
  );

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div style={{ position: 'relative' }}>
          <Navbar />
          <div
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 1000,
            }}
          >
            <ThemeToggle toggleTheme={toggleTheme} />
          </div>
        </div>
        <PageTransition darkMode={darkMode} />
      </Router>
    </ThemeProvider>
  );
}

function PageTransition({ darkMode }) {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Routes location={location}>
          <Route path="/" element={<Home darkMode={darkMode} />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/log" element={<Log />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}
