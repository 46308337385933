import React from 'react';
import { AiFillLinkedin, AiFillMail, AiFillGithub } from "react-icons/ai";
import "../App.css"; // Assuming the styles are in App.css

const Footer = () => {
  return (
    <div className="footer">
      <a href="mailto:gokhalearian@gmail.com" className="footer-link" aria-label="Email">
        <AiFillMail className="footer-icon" />
      </a>
      <a href="https://www.linkedin.com/in/ariangokhale/" className="footer-link" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
        <AiFillLinkedin className="footer-icon" />
      </a>
      <a href="https://github.com/ariangokhale" className="footer-link" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
        <AiFillGithub className="footer-icon" />
      </a>
    </div>
  );
};

export default Footer;
