import React from 'react';
import DropdownItem from './dropdownitem';

import microsoft_logo from './images/microsoft_logo.jpeg'
import accenture_logo from './images/accenture_logo.jpeg'
import wayfair_logo from './images/wayfair_logo.jpeg'

function Workdropdown() {
  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '10px auto 15px -20px' }}>
      <h3>work history</h3>

      <DropdownItem
        title="Microsoft"
        subtitle="software consultant"
        imageSrc={microsoft_logo} 
      >
        <ul>
          <li>Building API endpoints with Python to manage Azure Virtual Desktops and feed data into AI engines</li>
          <li>Created frontend components for internal web applications and AI bots (React)</li>
          <li>Produced Python-based automated storage system for metadata retrieval for Azure deployments</li>
        </ul>
      </DropdownItem>

      <DropdownItem
        title="Accenture"
        subtitle="tech analyst intern"
        imageSrc={accenture_logo} 
      >
        <ul>
          <li>Created Excel model to evaluate client technical project requests</li>
          <li>Automated legacy data entry procesess with Python and PowerBI</li>
          <li>Organized/updated epics, features, etc.</li>
        </ul>
      </DropdownItem>

      <DropdownItem
        title="Wayfair"
        subtitle="data science intern"
        imageSrc={wayfair_logo}
      >
        <ul>
          <li>Built dashboard to analyze conversion effect of viewing out-of-stock products during Covid</li>
          <li>Ran and monitored A/B tests for product reviews display</li>
          <li>Modeled impact of incremental product reviews on conversion rate</li>
        </ul>
      </DropdownItem>

    </div>
  );
}

export default Workdropdown;
