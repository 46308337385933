import React from 'react';
import Project from "../components/project";
import HeaderLine from '../components/header';
import Footer from '../components/footer';
import "../App.css";


export default function Projects() {

    const projects = [

        {
            projectTitle: "Spotify Monthly Playlist Generator",
            optionalDescription: "Creates a Spotify playlist based on listening history for the past month",
            techStack: "Python (Flask), HTML",
            gitHubRepo: "https://github.com/ariangokhale/spotify-monthly-playlist"
        },
        {
            projectTitle: "Bluebike Routing Application",
            optionalDescription: "Routes a user from one BB station to one closest to their destination",
            techStack: "Python (Flask), JavaScript, HTML/CSS",
            gitHubRepo: "https://github.com/ariangokhale/bluebike-routing-app"
        },

        {
            projectTitle: "Poker AI",
            optionalDescription: "Play poker against an AI in terminal",
            techStack: "Python, CFRM logic",
            gitHubRepo: "https://github.com/ariangokhale/DS3500-Final-NEU-FALL-2021"
        },

        {
            projectTitle: "Microsoft Data Science Toolkit",
            optionalDescription: "Did some front-end work here",
            techStack: "React",
            gitHubRepo: "https://www.ds-toolkit.com/"
        },

        {
            projectTitle: "Portfolio Website",
            optionalDescription: "you are here",
            techStack: "React",
            gitHubRepo: "https://ariangokhale.xyz/"
        }
    ]

    return (
        <div className="page-padding">
            <HeaderLine className="page-header" header="projects"/>

            {projects.map((project, i) => <Project projectTitle={project.projectTitle}
                                                   optionalDescription={project.optionalDescription}
                                                   techStack={project.techStack}
                                                   gitHubRepo={project.gitHubRepo}
                                                   picURL={project.picURL}/>)}
            
            <Footer />

        </div>
    );
  }