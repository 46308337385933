import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import "../App.css";

export default function Navbar() {
  const navigate = useNavigate();
  const theme = useTheme();

  const styles = {
    navbar: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      padding: '5px', // Reduced padding
      marginBottom: '0px', // Optional: Add margin to control space below the navbar
    },
    title: {
      cursor: 'pointer',
      color: theme.palette.text.primary,
      margin: '0', // Ensure no extra margin is added
    },
    navItems: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '5px', // Reduced margin
    },
    navItem: {
      margin: '0 8px', // Slightly reduce spacing between nav items
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
  };

  return (
    <nav style={styles.navbar}>
      <h1 style={styles.title} onClick={() => navigate('/')}>
        arian
      </h1>
      <div style={styles.navItems}>
        <NavLink style={styles.navItem} to="/about">about</NavLink>
        <NavLink style={styles.navItem} to="/projects">projects</NavLink>
        <NavLink style={styles.navItem} to="/log">log</NavLink>
      </div>
    </nav>
  );
}
