import React from 'react';
import '../App.css';

export default function HeaderLine(props) {
  return (
    <div className="page-header">
      <h2>{props.header}</h2>
    </div>
  );
}
