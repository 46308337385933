import React from 'react';
import HeaderLine from '../components/header';
import "../App.css"


export default function Log() {
    return (
        <div className="page-padding">
            <HeaderLine className="page-header" header="log"/>
            <p className="contacts">
                wip
            </p>
        </div>
    );
  }
  